
import { defineComponent, ref, reactive, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";

import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { string } from "yup/lib/locale";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Multiselect from "@vueform/multiselect";
import { Modal } from "bootstrap";

interface items {
  listSekolah: any;
  disableButton: boolean;

  role: string;

  comboschoolid: any;
  status: string;
}

export default defineComponent({
  name: "new-target-modal",
  components: {
    Loading,
    Multiselect,
    Field,
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const downloadModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      listSekolah: [],
      disableButton: false,

      role: "",

      comboschoolid: [
        {
          name: "Semua",
          _id: "",
        },
        {
          name: "Baru Ditambahkan",
          _id: "ADDED",
        },
        {
          name: "Menunggu Pembayaran",
          _id: "WAITING PAYMENT",
        },
        {
          name: "Proses Daftar PSP",
          _id: "ON PROCESS",
        },
        {
          name: "Gagal Daftar PSP",
          _id: "FAILED",
        },
        {
          name: "Migrasi",
          _id: "MIGRATED",
        },
      ],

      status: "",
    });

    const callModal = () => {
      console.log(JSON.parse(localStorage.getItem("sekolahId")!), "");
      const modal = new Modal(
        document.getElementById("kt_modal_download_sekolah")
      );
      modal.show();
    };

    const batalDownload = () => {
      items.status = "";
    };

    const downloadSekolah = (e) => {
      var role = JSON.parse(localStorage.getItem("user_account")!).role;

      var b =
        role == "ADMIN_SCHOOL"
          ? JSON.parse(localStorage.getItem("user_account")!).schoolId
          : JSON.parse(localStorage.getItem("sekolahId")!);

      if (b == null || b == "" || b == undefined) {
        return toast.error(
          "Gagal Unduh File Silahkan Pilih Sekolah Terlebih Dahulu"
        );
      }
      var namasekolah =
        role == "ADMIN_SCHOOL"
          ? JSON.parse(localStorage.getItem("user_account")!).name
          : JSON.parse(localStorage.getItem("schoolName")!);
      console.log(items.status, "aaa", b);
      var uu = "";
      if (items.status == "") {
        uu = "";
      } else {
        uu = "?status=" + items.status;
      }
      var url =
        role !== "ADMIN_SCHOOL"
          ? "crmv2/main_student/student/office/"
          : "crmv2/main_student/student/";
      if (items.status) {
        isLoading.value = true;
        ApiService.getDownload(url + b + "/data_student.xlsx" + uu)
          .then((response) => {
            // this.handleBlobResponse(response.data, item, extension);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            var extension = "xlsx";
            link.setAttribute(
              "download",
              "Data Sekolah " +
                namasekolah +
                " Status " +
                items.status +
                "." +
                extension
            ); // nama file dan extension sesuaikan dengan file yang di download
            document.body.appendChild(link);
            link.click();

            items.status = "";
            toast.success("Sukses Unduh File");
            hideModal(downloadModalRef.value);
            isLoading.value = false;
          })
          .catch((error) => {
            console.log(error);
            toast.error(
              "Gagal Unduh File Silahkan Pilih Sekolah Terlebih Dahulu"
            );
            isLoading.value = false;
          });
      } else {
        isLoading.value = true;

        ApiService.getDownload(url + b + "/data_student.xlsx")
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            var extension = "xlsx";
            link.setAttribute(
              "download",
              "Data Sekolah Semua " + namasekolah + " " + "." + extension
            ); // nama file dan extension sesuaikan dengan file yang di download
            document.body.appendChild(link);
            link.click();

            items.status = "";
            toast.success("Sukses Unduh File");
            hideModal(downloadModalRef.value);
            isLoading.value = false;
          })
          .catch((error) => {
            console.log(error);
            toast.error(
              "Gagal Unduh File Silahkan Pilih Sekolah Terlebih Dahulu"
            );
            isLoading.value = false;
          });
      }
    };

    const pilihstatus = (event) => {
      console.log(event);
      if (event) {
        items.status = event;
        //  localStorage.setItem("status", event);
      } else {
        items.status = "";
      }
    };

    onMounted(() => {
      items.role = JSON.parse(localStorage.getItem("user_account")!).role;
    });

    return {
      loading,
      formRef,
      downloadModalRef,
      items,

      downloadSekolah,
      batalDownload,
      callModal,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,

      pilihstatus,
    };
  },
});
